import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DAY_ORDER, WEEKDAYS, CLASS_TYPES } from "../common/Const";

const AttendanceList = ({ report, attendance, onEdit }) => {
  const uniqueClassTypes = [...new Set(attendance.map(reg => reg.classType))];

  const getClassTypeName = (classTypeId) => {
    const classType = CLASS_TYPES.find(type => type.id === classTypeId);
    return classType ? classType.name : classTypeId;
  };

  const [filters, setFilters] = useState({
    date: "",
    dayOfWeek: "",
    startTime: "",
    classType: "",
    studentName: "",
    teacherName: "",
    isPresent: false,
    comment: "",
  });
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [numToShow, setNumToShow] = useState(25);

  const sortedAttendance = useMemo(() => {
    const sortableItems = [...attendance];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === "isPresent") {
          return a.isPresent === b.isPresent
            ? 0
            : (a.isPresent ? 1 : -1) *
                (sortConfig.direction === "ascending" ? 1 : -1);
        }
        if (sortConfig.key === "dayOfWeek") {
          const dayA = DAY_ORDER[a[sortConfig.key]] || 8;
          const dayB = DAY_ORDER[b[sortConfig.key]] || 8;

          return sortConfig.direction === "ascending"
            ? dayA - dayB
            : dayB - dayA;
        }
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }

        return 0;
      });
    }

    return sortableItems;
  }, [attendance, sortConfig]);

  const filteredAttendance = sortedAttendance.filter(reg => (
    (filters.date === "" || reg.date.includes(filters.date)) &&
      (filters.dayOfWeek === "" || reg.dayOfWeek.includes(filters.dayOfWeek)) &&
      (filters.startTime === "" || reg.startTime.includes(filters.startTime)) &&
      (filters.classType === "" || reg.classType.includes(filters.classType)) &&
      (filters.studentName === "" ||
        reg.studentName.includes(filters.studentName)) &&
      (filters.teacherName === "" ||
        reg.teacherName.includes(filters.teacherName)) &&
      (!filters.isPresent || reg.isPresent) &&
      (filters.comment === "" || reg.comment.includes(filters.comment))
  ));

  const handleSort = (column) => {
    let direction = "ascending";
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };

  const getSortIcon = (column) => {
    if (sortConfig.key === column) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return <span style={{ visibility: "hidden" }}>▲</span>;
  };

  const handleShowMore = () => {
    setNumToShow(prev => prev + 10);
  };

  const handleShowAll = () => {
    setNumToShow(filteredAttendance.length);
  };

  return (
    <div>
      <div className="row d-flex mx-0 mb-3">
        <div className="manage-title">
          Raport obecności dla{" "}
          {report.clientId === "all" ||
          (!report.clientId && report.teacherId === "all")
            ? "wszystkich klientów"
            : report.clientName}{" "}
          od{" "}
          {report.teacherId === "all" ||
          (!report.teacherId && report.clientId === "all")
            ? "wszystkich nauczycieli"
            : `${report.teacherName}`}{" "}
          - {moment(report.startDate).format("YYYY-MM-DD")} -{" "}
          {moment(report.endDate).format("YYYY-MM-DD")}
        </div>
        <div className="col-block ml-auto">
          <button
            type="button"
            className="btn manage-button btn-success btn-block"
            onClick={onEdit}
          >
            Zmień raport
          </button>
        </div>
      </div>

      <div className="row mx-0 d-flex align-items-center report-filters mb-3">
        <div className="col-sm-1 p-0 pr-2">
          <input
            type="text"
            className="form-control w-100"
            placeholder=""
            value={filters.date}
            onChange={e => setFilters({ ...filters, date: e.target.value })}
          />
        </div>
        <div className="col-sm-1 p-0 pr-2">
          <select
            className="form-control w-100"
            value={filters.dayOfWeek}
            onChange={e => setFilters({ ...filters, dayOfWeek: e.target.value })
            }
          >
            <option value="" />
            {WEEKDAYS.map(weekday => (
              <option key={weekday.id} value={weekday.name}>
                {weekday.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-1 p-0 pr-2">
          <input
            type="text"
            className="form-control w-100"
            placeholder=""
            value={filters.startTime}
            onChange={e => setFilters({ ...filters, startTime: e.target.value })
            }
          />
        </div>
        <div className="col-sm-2 p-0 pr-2">
          <select
            className="form-control w-100"
            value={filters.classType}
            onChange={e => setFilters({ ...filters, classType: e.target.value })
            }
          >
            <option value="" />
            {uniqueClassTypes.map(classTypeId => (
              <option key={classTypeId} value={classTypeId}>
                {getClassTypeName(classTypeId)}
              </option>
            ))}
          </select>
        </div>

        <div className="col-sm-2 p-0 pr-2">
          <input
            type="text"
            className="form-control w-100"
            placeholder=""
            value={filters.studentName}
            onChange={e => setFilters({ ...filters, studentName: e.target.value })
            }
          />
        </div>
        <div className="col-sm-2 p-0 pr-2">
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={filters.teacherName}
            onChange={e => setFilters({ ...filters, teacherName: e.target.value })
            }
          />
        </div>
        <div className="col-sm-1 text-center report-checkbox p-0 pr-2">
          <input
            className="w-100"
            type="checkbox"
            checked={filters.isPresent}
            onChange={e => setFilters({ ...filters, isPresent: e.target.checked })
            }
          />
        </div>
        <div className="col-sm-2 p-0">
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={filters.comment}
            onChange={e => setFilters({ ...filters, comment: e.target.value })
            }
          />
        </div>
      </div>

      <div className="">
        <div className="row table-header manage-table-title mx-0 d-flex align-items-center">
          <div
            className="col-sm-1 sortable-column"
            onClick={() => handleSort("date")}
          >
            <span className="text-with-icon pl-2 ">Data</span>
            {getSortIcon("date")}
          </div>
          <div
            className="col-sm-1 sortable-column"
            onClick={() => handleSort("dayOfWeek")}
          >
            <span className="text-with-icon pl-2 ">Dzień tyg.</span>
            {getSortIcon("dayOfWeek")}
          </div>
          <div
            className="col-sm-1 sortable-column"
            onClick={() => handleSort("startTime")}
          >
            <span className="text-with-icon pl-2 ">Godzina</span>
            {getSortIcon("startTime")}
          </div>
          <div
            className="col-sm-2 sortable-column"
            onClick={() => handleSort("classType")}
          >
            <span className="text-with-icon pl-2 ">Typ lekcji</span>
            {getSortIcon("classType")}
          </div>
          <div
            className="col-sm-2 sortable-column"
            onClick={() => handleSort("studentName")}
          >
            <span className="text-with-icon pl-2 ">Uczeń</span>
            {getSortIcon("studentName")}
          </div>
          <div
            className="col-sm-2 sortable-column"
            onClick={() => handleSort("teacherName")}
          >
            <span className="text-with-icon pl-2 ">Nauczyciel</span>
            {getSortIcon("teacherName")}
          </div>
          <div
            className="col-sm-1 sortable-column"
            onClick={() => handleSort("isPresent")}
          >
            <span className="text-with-icon pl-2 ">Obecność</span>
            {getSortIcon("isPresent")}
          </div>
          <div
            className="col-sm-2 sortable-column"
            onClick={() => handleSort("comment")}
          >
            <span className="text-with-icon pl-2 ">Uwagi</span>
            {getSortIcon("comment")}
          </div>
        </div>

        <div className="">
          {filteredAttendance.slice(0, numToShow).map((reg, index) => (
            <div
              key={`${reg.id}-${index}`}
              className={`table-row row d-flex manage-text cursor-pointer mx-0 ${
                index % 2 !== 0 ? "table-row-alternate" : ""
              }`}
              // onClick={() => onEdit(reg)}
            >
              <div className="col-sm-1 text-center">{reg.date}</div>
              <div className="col-sm-1 text-center">{reg.dayOfWeek}</div>
              <div className="col-sm-1 text-center">{reg.startTime}</div>
              <div className="col-sm-2 text-center">
                {getClassTypeName(reg.classType)}
              </div>
              <div className="col-sm-2 text-center">{reg.studentName}</div>
              <div className="col-sm-2 text-center">{reg.teacherName}</div>
              <div className="col-sm-1 text-center">
                {reg.isPresent && <i className="fas fa-check" />}
              </div>
              <div className="col-sm-2 text-center">{reg.comment}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="pt-3 row d-flex justify-content-center mx-0">
        {numToShow < attendance.length && (
          <div className="col-md-3 form-group">
            <button
              type="button"
              className="btn manage-button manage-red-button btn-block"
              onClick={handleShowMore}
            >
              Pokaż więcej
            </button>
          </div>
        )}

        {numToShow < attendance.length && (
          <div className="col-md-3 form-group">
            <button
              type="button"
              className="btn manage-button btn-dark btn-block"
              onClick={handleShowAll}
            >
              Pokaż wszystkie
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

AttendanceList.propTypes = {
  report: PropTypes.shape({
    year: PropTypes.string,
    month: PropTypes.string,
    teacherName: PropTypes.string,
    clientName: PropTypes.string,
  }).isRequired,
  attendance: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      date: PropTypes.string,
      dayOfWeek: PropTypes.string,
      startTime: PropTypes.string,
      classType: PropTypes.string,
      studentName: PropTypes.string,
      teacherName: PropTypes.string,
      isPresent: PropTypes.bool,
      comment: PropTypes.string,
    })
  ).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default AttendanceList;
