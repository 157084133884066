import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import AttendanceReportCalendar from "./AttendanceReportCalendar";
import { MONTHS } from "../common/Const";
import SelectInput from "../common/SelectInput";

const generateYearsArray = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = 2020; year <= currentYear; year++) {
    years.push({
      id: year,
      name: year.toString(),
    });
  }

  return years;
};

const YEARS = generateYearsArray();

const validateDateOrder = (sDate, eDate, fullMonthChange = false) => {
  if (eDate && sDate && eDate <= sDate) {
    if (!fullMonthChange) {
      return {start: eDate, end: eDate, repeat: true};
    }
    return {
      start: moment(eDate).startOf("month").toDate(),
      end: eDate,
      repeat: true,
    };
  }
  return { start: sDate, end: eDate, repeat: false };
};

const AttendanceReportForm = ({
  report,
  onCancel,
  touched,
  errors,
  onBlur,
  onChange,
  onSubmit,
  onSelectClient,
  onSelectTeacher,
  onClearTeacher,
  onClearClient,
  onDateSelection,
  status,
}) => {
  const { startDate, endDate } = report;
  const [selectionState, setSelectionState] = useState(0);
  const [firstCalendarDate, setFirstCalendarDate] = useState(startDate);
  const [secondCalendarDate, setSecondCalendarDate] = useState(endDate);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleNavigateFirstCalendar = (date) => {
    const newFirstCalendarDate = moment.utc(date);
    if (moment(newFirstCalendarDate).isBefore(secondCalendarDate)) {
      setFirstCalendarDate(newFirstCalendarDate.toDate());
    } else {
      setFirstCalendarDate(
        moment(secondCalendarDate).subtract(1, "month").toDate()
      );
    }
  };


  const handleNavigateSecondCalendar = (date) => {
    const updatedDate = moment.utc(date);
    if (updatedDate.isBefore(moment(firstCalendarDate).add(1, "month"))) {
      setSecondCalendarDate(updatedDate.toDate());
      setFirstCalendarDate(moment(updatedDate).subtract(1, "month").toDate());
    } else {
      setSecondCalendarDate(updatedDate.toDate());
    }
  };


  return (
    <div>
      <h2 className="manage-title mb-3">Raport obecności</h2>

      <div className="month-range-selector">
        <div className="row mb-5">
          <div className="col-md-6 col-12">
            <div className="row">
              <div className="col">
                <SelectInput
                  label="Miesiąc początkowy"
                  onBlur={onBlur}
                  onChange={onChange}
                  name="startMonth"
                  value={moment(startDate).month()}
                  error={errors.startMonth}
                  readOnly={false}
                  defaultOption={null}
                  options={MONTHS.map(month => ({
                    value: month.id,
                    text: month.name,
                  }))}
                />
              </div>
              <div className="col">
                <SelectInput
                  label="Rok początkowy"
                  onBlur={onBlur}
                  onChange={onChange}
                  name="startYear"
                  value={moment(startDate).year()}
                  error={errors.startMonth}
                  readOnly={false}
                  defaultOption={null}
                  options={YEARS.map(year => ({
                    value: year.id,
                    text: year.name,
                  }))}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="row">
              <div className="col">
                <SelectInput
                  label="Miesiąc Końcowy"
                  onBlur={onBlur}
                  onChange={onChange}
                  name="endMonth"
                  value={moment(endDate).month()}
                  error={errors.endDate}
                  readOnly={false}
                  defaultOption={null}
                  options={MONTHS.map(month => ({
                    value: month.id,
                    text: month.name,
                  }))}
                />
              </div>
              <div className="col">
                <SelectInput
                  label="Rok końcowy"
                  onBlur={onBlur}
                  onChange={onChange}
                  name="endYear"
                  value={moment(endDate).year()}
                  error={errors.endYear}
                  readOnly={false}
                  defaultOption={null}
                  options={YEARS.map(year => ({
                    value: year.id,
                    text: year.name,
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row date-range-display mb-3 d-flex justify-content-center">
          <h5 className="col-xl-6 col-lg-12 mb-0">
            <div className="row">
              <div className="col-sm-8">
                Wybrano zakres dat: &nbsp;
                <strong>
                  {startDate ? moment(startDate).format("YYYY-MM-DD") : "  "}{" "}
                  &nbsp;-&nbsp;
                  {endDate ? moment(endDate).format("YYYY-MM-DD") : "  "}
                </strong>
              </div>
              <div className="col-sm-4">
                <button
                  className="btn btn-primary w-100 btn-block manage-button"
                  type="button"
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  {`Dokładny zakres dat ${showCalendar ? "-" : "+"}`}
                </button>
              </div>
            </div>
          </h5>
        </div>
      </div>
      <div
        id="report-calendar"
        className={`${
          showCalendar ? "mb-3  custom-collapse show" : "custom-collapse"
        }`}
      >
        <AttendanceReportCalendar
          onDateSelection={onDateSelection}
          onNavigateFirstCalendar={handleNavigateFirstCalendar}
          onNavigateSecondCalendar={handleNavigateSecondCalendar}
          firstCalendarDate={firstCalendarDate}
          secondCalendarDate={secondCalendarDate}
          validateDateOrder={validateDateOrder}
          startDate={report.startDate}
          endDate={report.endDate}
          selectionState={selectionState}
          setSelectionState={setSelectionState}
        />
      </div>
      <div className="row mb-3 justify-content-center">
        <div className="col-xl-6 col-lg-12">
          <div className="row mb-3 align-items-center">
            <div className="col-sm-8">
              <h5 className="mb-0">
                Wybrano nauczyciela: &nbsp;{" "}
                <strong>{report.teacherName}</strong>
              </h5>
            </div>
            <div className="col-sm-4 text-start align-items-center">
              <button
                type="button"
                className="btn manage-teal-button w-100 btn btn-block manage-button manage-teal-button"
                onClick={() => onSelectTeacher()}
              >
                Wybierz nauczyciela
              </button>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-sm-8">
              <h5 className="mb-0">
                Wybrano klienta: &nbsp; <strong>{report.clientName}</strong>
              </h5>
            </div>
            <div className="col-sm-4 text-start align-items-center">
              <button
                type="button"
                className="btn manage-teal-button w-100 btn btn-block manage-button manage-teal-button"
                onClick={() => onSelectClient()}
              >
                Wybierz Klienta
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-4 row justify-content-center">
        <form
          id="attendance-report-form"
          onSubmit={onSubmit}
          className="col-6 mt-4"
        >
          <div className="row">
            <div className="col">
              <button
                type="submit"
                className="btn w-100 btn btn-block manage-button btn-success"
              >
                Wygeneruj raport
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn w-100 btn btn-block manage-button manage-red-button"
                onClick={onCancel}
              >
                Anuluj
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

AttendanceReportForm.propTypes = {
  report: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSelectClient: PropTypes.func.isRequired,
  onSelectTeacher: PropTypes.func.isRequired,
  onClearTeacher: PropTypes.func.isRequired,
  onClearClient: PropTypes.func.isRequired,
  onDateSelection: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default AttendanceReportForm;
