import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import ListItemSelector from "./ListItemSelector";

const SelectorModal = ({
  isOpen,
  onClose,
  values,
  onSelect,
  title,
  selectedIDs = [],
}) => (
  <Modal show={isOpen} onHide={onClose} centered>
    <Modal.Body>
      <div className="modal-title mb-4">{title}</div>
      <ListItemSelector
        label=""
        options={values}
        onSelect={onSelect}
        selectedIDs={selectedIDs}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button className="manage-button manage-red-button" onClick={onClose}>
        Zamknij
      </Button>
    </Modal.Footer>
  </Modal>
);

SelectorModal.defaultProps = {
  selectedIDs: [],
};

SelectorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  selectedIDs: PropTypes.arrayOf(PropTypes.string),
};

export default SelectorModal;
