import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import SchoolClassForm from "./SchoolClassForm";
import {CLASS_TYPES, STATUS, WEEKDAYS} from "../common/Const";
import {mandatory, validateValues} from "../common/Validations";
import {history} from "../../redux/store/configureStore";
import * as schoolClassActions from "../../redux/actions/schoolClassActions";
import * as classStudentsActions from "../../redux/actions/classStudentsActions";
import * as teacherActions from "../../redux/actions/teacherActions";
import * as studentActions from "../../redux/actions/studentActions";
import * as bookActions from "../../redux/actions/bookActions";
import * as lessonRegisterActions from "../../redux/actions/lessonRegisterActions";
import {
  calculateDatesBetween,
  findDayOfWeek,
  formatDate,
  formatDayOfWeek,
  getById,
  hasErrors,
  hasNoErrors,
  minutesToTimeString,
  timeStringToMinutes,
  getWeek,
  getStudentsInThisClass,
} from "../common/Helpers";
import LessonsList from "./LessonsList";
import StudentsList from "./StudentsList";
import ListItemSelector from "../common/ListItemSelector";

const validationConfig = {
  classTypeId: {
    label: "Typ zajęć",
    validate: [mandatory],
  },
  dayOfWeek: {
    label: "Dzień tygodnia",
    validate: [mandatory],
  },
  startTime: {
    label: "Początek zajęć",
    validate: [mandatory],
  },
  teacherId: {
    label: "Nauczyciel",
    validate: [mandatory],
  },
};

function getName(person) {
  return person ? `${person.firstName || ''} ${person.lastName || ''}` : '';
}

function datesOverlap(from1, till1, from2, till2) {
  return (from1 >= from2 && till2 > from1) || (till1 > from2 && till2 >= till1) || (from1 <= from2 && till1 >= till2);
}

function timesOverlap(from1, till1, from2, till2) {
  return (from1 >= from2 && till2 > from1) || (till1 > from2 && till2 >= till1) || (from1 <= from2 && till1 >= till2);
}

const SchoolClass = ({
  schoolClasses, loadSchoolClasses, teachers, loadTeachers, saveSchoolClass, scheduledLessons,
  students, loadStudents, classStudents, loadClassStudents, studentsInThisClass, studentsNotInThisClass,
  addClassStudent, removeClassStudent, saveLessonRegister, loadLessonRegisters, deleteLessonRegister, lessonRegister, books, loadBooks,
  ...props
}) => {
  // eslint-disable-next-line react/destructuring-assignment
  const [schoolClass, setSchoolClass] = useState({ ...props.schoolClass });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [status, setStatus] = useState(STATUS.VIEW);
  const [showSelectStudentModal, setShowSelectStudentModal] = useState(false);
  const [dateReadOnly, setDateReadOnly] = useState(false);


  useEffect(() => {
    if (schoolClasses.length === 0) {
      loadSchoolClasses().catch((error) => {
        alert(`Loading schoolClasses failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    setSchoolClass({...props.schoolClass});
    if (props.schoolClass.id) {
      setStatus(STATUS.VIEW);
    } else {
      setStatus(STATUS.IDLE);
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.schoolClass]);

  useEffect(() => {
    if (teachers.length === 0) {
      loadTeachers().catch((error) => {
        alert(`Loading teachers failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    if (classStudents.length === 0) {
      loadClassStudents().catch((error) => {
        alert(`Loading classStudents failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    if (students.length === 0) {
      loadStudents().catch((error) => {
        alert(`Loading students failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    if (books.length === 0) {
      loadBooks().catch((error) => {
        alert(`Loading books failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    if (lessonRegister.length === 0) {
      loadLessonRegisters().catch((error) => {
        alert(`Loading lessonRegisters failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    let hasNonEmptyLessonRegister = false;

    scheduledLessons.forEach((lesson) => {
      if (lesson.lessonRegister !== null) {
        hasNonEmptyLessonRegister = true;
      }
    });

    setDateReadOnly(hasNonEmptyLessonRegister);
  }, [scheduledLessons]);

  function getSchoolClassName(aSchoolClass) {
    const {startTime, dayOfWeek, classTypeId} = aSchoolClass;
    const dayName = findDayOfWeek(dayOfWeek).name;
    const selectedClassType = CLASS_TYPES.find(value => value.id === classTypeId);
    const startTimeMinutes = timeStringToMinutes(startTime);
    const endTime = (selectedClassType && startTimeMinutes) ? minutesToTimeString(startTimeMinutes + selectedClassType.length) : "";
    return `${dayName} ${selectedClassType.name} ${startTime}-${endTime}`;
  }

  function findOverlapSchoolClass() {
    const {id, teacherId, dayOfWeek, startTime, classTypeId, fromDate, tillDate} = schoolClass;
    if (!teacherId || !dayOfWeek || !startTime || !classTypeId || !fromDate || !tillDate) {
      return undefined;
    }
    const startTimeMinutes = timeStringToMinutes(startTime);
    const selectedClassType = CLASS_TYPES.find(value => value.id === classTypeId);
    const endTime = (selectedClassType && startTimeMinutes) ? minutesToTimeString(startTimeMinutes + selectedClassType.length) : "";
    return schoolClasses.find((sc) => {
      const scStartTimeMinutes = timeStringToMinutes(sc.startTime);
      const scSelectedClassType = CLASS_TYPES.find(value => value.id === sc.classTypeId);
      const scEndTime = (scSelectedClassType && scStartTimeMinutes) ? minutesToTimeString(scStartTimeMinutes + scSelectedClassType.length) : "";
      return sc.id !== id && sc.teacherId === teacherId && sc.dayOfWeek === dayOfWeek &&
        datesOverlap(sc.fromDate, sc.tillDate, fromDate, tillDate) &&
        timesOverlap(startTime, endTime, sc.startTime, scEndTime);
    });
  }

  const validate = () => {
    const {isRecurring, dayOfWeek, fromDate, tillDate} = schoolClass;
    const fromDateDayOfWeek = WEEKDAYS.find(d => d.day === (new Date(fromDate)).getDay());
    // eslint-disable-next-line no-shadow
    const errors = validateValues(schoolClass, validationConfig);
    if (!fromDate) {
      errors.fromDate = "Pole jest obowiązkowe";
    }
    if (!isRecurring && tillDate !== fromDate) {
      setSchoolClass({...schoolClass, tillDate: fromDate});
    }
    if (!isRecurring && dayOfWeek && fromDate && fromDateDayOfWeek.id !== dayOfWeek) {
      errors.dayOfWeek = "Dzień tygodnia nie zgadza się z datą";
    }
    if (isRecurring && !tillDate) {
      errors.tillDate = "Pole jest obowiązkowe";
    }
    if (isRecurring && fromDate && tillDate && fromDate > tillDate) {
      errors.fromDate = "Pierwszy tydzień musi być przed ostatnim";
      errors.tillDate = "Pierwszy tydzień musi być przed ostatnim";
    }
    // check teacher does not have any other class at the same time
    const overlapped = findOverlapSchoolClass();
    if (overlapped) {
      const message = `Zajęcia się pokrywają z ${getSchoolClassName(overlapped)}`;
      errors.saveError = message;
    }
    // check that if class is not recurrent than fromDate must be equal to tillDate
    // check that if class is  recurrent than fromDate must be before tillDate and both must be populated
    setErrors(errors);
    return hasNoErrors(errors);
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    setTouched({ ...touched, [name]: true });
    validate();
  };

  const handleCancel = () => {
    if (status === STATUS.VIEW || !schoolClass.id) {
      history.push('/manage/classes');
    } else {
      setSchoolClass(props.schoolClass);
      setStatus(STATUS.VIEW);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSchoolClass(prevClass => ({...prevClass, [name]: value}));
    validate();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus(STATUS.SUBMITTING);
    const isValid = validate();

    if (isValid) {
      saveSchoolClass(schoolClass)
        .then((savedClass) => {
          setStatus(STATUS.VIEW);
          if (!schoolClass.id) {
            history.push(`/manage/class/${savedClass.id}`);
          }
        })
        .catch((error) => {
          setErrors({...errors, saveError: error.message});
          setStatus(STATUS.SUBMITTED);
        });
    } else {
      setStatus(STATUS.SUBMITTED);
    }
  };

  const handleRegisterUpdate = async (event) => {
    saveLessonRegister(event.lessonRegister)
      .then((savedRegister) => {
        setStatus(STATUS.VIEW);
      })
      .catch((error) => {
        setErrors({...errors, saveError: error.message});
        setStatus(STATUS.SUBMITTED);
      });
  };

  const handleEdit = () => {
    setStatus(STATUS.IDLE);
  };

  const handleAddStudent = (student) => {
    const classStudent = {schoolClassId: schoolClass.id, studentId: student.value};
    addClassStudent(classStudent)
      .then(() => {
        setShowSelectStudentModal(false);
      })
      .catch((error) => {
        setErrors({...errors, saveError: error.message});
      });
  };

  const handleRemoveStudent = (student) => {
    const {classStudent} = student;
    removeClassStudent(classStudent)
      .then(() => {
        setShowSelectStudentModal(false);
      })
      .catch((error) => {
        setErrors({...errors, saveError: error.message});
      });
  };

  return (
    <div className="mb-5">
      { hasErrors(errors) && status === STATUS.SUBMITTED && (
        <div role="alert" className="alert alert-danger">
          {Object.keys(errors).map(key => <div key={key}>{errors[key]}</div>)}
        </div>
      )}

      <SchoolClassForm
        classTypes={CLASS_TYPES}
        teachers={teachers}
        weekdays={WEEKDAYS}
        books={books}
        id={schoolClass.id}
        teacherId={schoolClass.teacherId}
        classTypeId={schoolClass.classTypeId}
        bookId={schoolClass.bookId}
        dayOfWeek={schoolClass.dayOfWeek}
        comment={schoolClass.comment}
        startTime={schoolClass.startTime}
        isRecurring={schoolClass.isRecurring}
        fromDate={schoolClass.fromDate}
        tillDate={schoolClass.tillDate}
        touched={touched}
        errors={errors}
        onBlur={handleBlur}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onEdit={handleEdit}
        onCancel={handleCancel}
        status={status}
        dateReadOnly={dateReadOnly}
      />

      <Modal show={showSelectStudentModal} onHide={() => setShowSelectStudentModal(false)} centered>
        <Modal.Body>
          <div className="modal-title mb-2">Wybierz ucznia</div>
          <ListItemSelector label="" onSelect={handleAddStudent} options={studentsNotInThisClass} />
        </Modal.Body>
      </Modal>

      {status === STATUS.VIEW && (
        <StudentsList students={studentsInThisClass} onViewParent={() => {}} onEdit={null} onRemove={null} onAddStudent={null}/>
      )}
      {status !== STATUS.VIEW && schoolClass.id && (
        <StudentsList students={studentsInThisClass} onViewParent={() => {}} onEdit={null} onRemove={handleRemoveStudent} onAddStudent={() => setShowSelectStudentModal(true)}/>
      )}

      { schoolClass.id && (
        <LessonsList
          scheduledLessons={scheduledLessons}
          students={students}
          teachers={teachers}
          onSubmit={handleRegisterUpdate}
          deleteLessonRegister={deleteLessonRegister}
        />
      )}
    </div>
  );
};

SchoolClass.propTypes = {
  schoolClasses: PropTypes.array.isRequired,
  teachers: PropTypes.array.isRequired,
  schoolClass: PropTypes.object.isRequired,
  loadSchoolClasses: PropTypes.func.isRequired,
  saveSchoolClass: PropTypes.func.isRequired,
  loadTeachers: PropTypes.func.isRequired,
  scheduledLessons: PropTypes.array.isRequired,
  students: PropTypes.array.isRequired,
  loadStudents: PropTypes.func.isRequired,
  classStudents: PropTypes.array.isRequired,
  addClassStudent: PropTypes.func.isRequired,
  removeClassStudent: PropTypes.func.isRequired,
  loadClassStudents: PropTypes.func.isRequired,
  studentsInThisClass: PropTypes.array.isRequired,
  studentsNotInThisClass: PropTypes.array.isRequired,
  saveLessonRegister: PropTypes.func.isRequired,
  deleteLessonRegister: PropTypes.func.isRequired,
  loadLessonRegisters: PropTypes.func.isRequired,
  lessonRegister: PropTypes.array.isRequired,
  books: PropTypes.array.isRequired,
  loadBooks: PropTypes.func.isRequired,
};

const findLessonRegister = (lessonRegister, lessonId) => {
  return lessonRegister.find(lr => lr.lessonId === lessonId) || null;
};

const createLessonObject = (id, index, date, startTime, teacherId, dayOfWeek, classTypeId, isRecurring, students, classStudents) => ({
  id: `${id}-${index}`,
  defaultLessonDetails:
  {
    date: date instanceof Date ? formatDate(date) : date,
    dayOfWeek: dayOfWeek,
    startTime: startTime,
    teacherId: teacherId,
    classTypeId: classTypeId,
    isRecurring: isRecurring,
    comment: "",
  },
  defaultStudents: getStudentsInThisClass(students, classStudents, id),
});

const calculateScheduledClasses = (schoolClass, lessonRegister, students, classStudents) => {
  const {
    id,
    startTime,
    teacherId,
    dayOfWeek,
    isRecurring,
    fromDate,
    tillDate,
    classTypeId,
  } = schoolClass;

  if (!dayOfWeek || !fromDate) {
    return [];
  }

  const dates = isRecurring && tillDate ? calculateDatesBetween(fromDate, tillDate, dayOfWeek) : [fromDate];
  const lessonsArray = dates.map((date, index) => createLessonObject(id, index, date, startTime, teacherId, dayOfWeek, classTypeId, isRecurring, students, classStudents));

  return lessonsArray.map(lesson => ({
    ...lesson,
    lessonRegister: findLessonRegister(lessonRegister, lesson.id),
  }));
};

function getStudentsNotInThisClass(students, classStudents, schoolClassId) {
  const studentIds = classStudents.filter(cs => cs.schoolClassId === schoolClassId).map(cs => cs.studentId);
  return students.filter(s => !studentIds.includes(s.id)).map(s => ({value: s.id, text: getName(s)}));
}

const mapStateToProps = (state, ownProps) => {
  const {id} = ownProps.match.params;
  const schoolClass =
    id === "new" || state.schoolClasses.length === 0
      ? { isRecurring: true}
      : getById(state.schoolClasses, id);

  const scheduledLessons = calculateScheduledClasses(schoolClass, state.lessonRegister, state.students, state.classStudents);
  const studentsInThisClass =
    state.students && state.classStudents && schoolClass.id
      ? getStudentsInThisClass(state.students, state.classStudents, schoolClass.id)
      : [];
  const studentsNotInThisClass =
    state.students && state.classStudents && schoolClass.id
      ? getStudentsNotInThisClass(state.students, state.classStudents, schoolClass.id)
      : [];

  return {
    schoolClass,
    schoolClasses: state.schoolClasses,
    teachers: state.teachers,
    students: state.students,
    books: state.books,
    scheduledLessons,
    classStudents: state.classStudents,
    studentsInThisClass,
    studentsNotInThisClass,
    lessonRegister: state.lessonRegister,
  };
};

const mapDispatchToProps = {
  loadSchoolClasses: schoolClassActions.loadSchoolClasses,
  saveSchoolClass: schoolClassActions.saveSchoolClass,
  loadClassStudents: classStudentsActions.loadClassStudents,
  addClassStudent: classStudentsActions.addClassStudent,
  removeClassStudent: classStudentsActions.removeClassStudent,
  loadTeachers: teacherActions.loadTeachers,
  loadStudents: studentActions.loadStudents,
  loadBooks: bookActions.loadBooks,
  saveLessonRegister: lessonRegisterActions.saveLessonRegister,
  loadLessonRegisters: lessonRegisterActions.loadLessonRegisters,
  deleteLessonRegister: lessonRegisterActions.deleteLessonRegister,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolClass);
