import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import moment from "moment";
import SelectorModal from "../common/SelectorModal";
import AttendanceReportForm from "./AttendanceReportForm";
import { STATUS } from "../common/Const";
import { history } from "../../redux/store/configureStore";
import { mandatory, validateValues } from "../common/Validations";
import {
  formatDayOfWeek,
  getPersonName,
  getClassType,
  getStudentsByClientId,
  hasErrors,
  hasNoErrors,
} from "../common/Helpers";
import * as lessonRegisterActions from "../../redux/actions/lessonRegisterActions";
import * as schoolClassActions from "../../redux/actions/schoolClassActions";
import * as teacherActions from "../../redux/actions/teacherActions";
import * as studentActions from "../../redux/actions/studentActions";
import * as clientActions from "../../redux/actions/clientActions";
import * as classStudentsActions from "../../redux/actions/classStudentsActions";
import AttendanceList from "./AttendanceList";

const validationConfig = {
  startDate: {
    label: "data startowa",
    validate: [mandatory],
  },
  endDate: {
    label: "data końcowa",
    validate: [mandatory],
  },
};

function AttendanceReport({
  lessonRegister,
  loadLessonRegisters,
  schoolClasses,
  loadSchoolClasses,
  teachers,
  loadTeachers,
  students,
  loadStudents,
  classStudents,
  loadClassStudents,
  clients,
  loadClients,
  clientOptions,
  teacherOptions,
  ...props
}) {
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [status, setStatus] = useState(STATUS.VIEW);
  const [showSelectTeacherModal, setShowSelectTeacherModal] = useState(false);
  const [showSelectClientModal, setShowSelectClientModal] = useState(false);
  const [report, setReport] = useState({
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    teacherId: null,
    teacherName: "",
    clientId: null,
    clientName: "",
  });
  const [attendance, setAttendance] = useState([]);

  // Load data functions
  useEffect(() => {
    if (schoolClasses.length === 0) {
      loadSchoolClasses().catch(error => alert(`Loading schoolClasses failed ${error}`));
    }
    if (teachers.length === 0) {
      loadTeachers().catch(error => alert(`Loading teachers failed ${error}`));
    }
    if (classStudents.length === 0) {
      loadClassStudents().catch(error => alert(`Loading classStudents failed ${error}`));
    }
    if (students.length === 0) {
      loadStudents().catch(error => alert(`Loading students failed ${error}`));
    }
    if (lessonRegister.length === 0) {
      loadLessonRegisters().catch(error => alert(`Loading lessonRegisters failed ${error}`));
    }
    if (clients.length === 0) {
      loadClients().catch(error => alert(`Loading clients failed ${error}`));
    }
  }, [
    schoolClasses,
    teachers,
    classStudents,
    students,
    lessonRegister,
    clients,
  ]);

  const validate = () => {
    const { teacherId, clientId } = report;
    const err = validateValues(report, validationConfig);
    if (!teacherId && !clientId) {
      err.tillDate = "Należy wybrać nauczyciela lub klienta";
    }
    setErrors(err);
    return hasNoErrors(err);
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    setTouched({ ...touched, [name]: true });
    validate();
  };

  const handleCancel = () => history.goBack();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setReport((prevReport) => {
      let {startDate, endDate} = prevReport;

      if (name === "startMonth") {
        startDate = moment(startDate).set("month", value).startOf("month").toDate();
      }
      if (name === "startYear") {
        startDate = moment(startDate).set("year", value).startOf("month").toDate();
      }
      if (name === "endMonth") {
        endDate = moment(endDate).set("month", value).endOf("month").toDate();
      }
      if (name === "endYear") {
        endDate = moment(endDate).set("year", value).endOf("month").toDate();
      }

      return ({...prevReport, [name]: value.toString(), startDate, endDate});
    });
    validate();
  };

  const calculateAttendance = () => {
    let studentsOfClient = getStudentsByClientId(students, report.clientId);
    studentsOfClient = studentsOfClient.map(s => s.id);
    const { startDate, endDate, teacherId } = report;
    const newAttendance = lessonRegister
      .filter(
        lr => new Date(lr.lessonDetails.date) >= startDate &&
          new Date(lr.lessonDetails.date) <= endDate
      )
      .filter(
        lr => teacherId === "all" ||
          lr.lessonDetails.teacherId === teacherId ||
          teacherId === null
      )

      .flatMap(lr => lr.students
        .filter(
          s => studentsOfClient.includes(s.id) || report.clientId === null
        )
        .map(s => ({ ...lr, student: s })))
      .map(lr => ({
        id: lr.id,
        date: lr.lessonDetails.date,
        dayOfWeek: formatDayOfWeek(new Date(lr.lessonDetails.date)),
        startTime: lr.lessonDetails.startTime,
        classType: getClassType(lr.lessonId, schoolClasses),
        studentName: getPersonName(lr.student.id, students),
        teacherName: getPersonName(lr.lessonDetails.teacherId, teachers),
        isPresent: lr.student.isPresent,
        comment: lr.lessonDetails.comment,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
    setAttendance(newAttendance);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus(STATUS.SUBMITTING);
    const isValid = validate();

    if (isValid) {
      calculateAttendance();
      setStatus(STATUS.COMPLETED);
    } else {
      setStatus(STATUS.SUBMITTED);
    }
  };

  const handleSelectTeacher = (teacher) => {
    setReport(prevReport => ({
      ...prevReport,
      teacherId: teacher.value,
      teacherName: teacher.text,
    }));
    setShowSelectTeacherModal(false);
  };

  const handleSelectClient = (client) => {
    setReport(prevReport => ({
      ...prevReport,
      clientId: client.value,
      clientName: client.text,
    }));
    setShowSelectClientModal(false);
  };

  const handleClearTeacher = () => {
    setReport(prevReport => ({
      ...prevReport,
      teacherId: null,
      teacherName: "",
    }));
    setShowSelectTeacherModal(false);
  };

  const handleClearClient = () => {
    setReport(prevReport => ({
      ...prevReport,
      clientId: null,
      clientName: "",
    }));
    setShowSelectClientModal(false);
  };

  const handleChangeReport = () => {
    setStatus(STATUS.VIEW);
  };

  const handleDateSelection = (start = null, end = null) => {
    setReport(prevReport => ({
      ...prevReport,
      startDate: start || prevReport.startDate,
      endDate: end || prevReport.endDate,
    }));
  };

  return (
    <div className="mb-5">
      {hasErrors(errors) && status === STATUS.SUBMITTED && (
        <div role="alert" className="alert alert-danger">
          {Object.keys(errors).map(key => (
            <div key={key}>{errors[key]}</div>
          ))}
        </div>
      )}
      {(status === STATUS.VIEW || status === STATUS.SUBMITTED) && (
        <AttendanceReportForm
          report={report}
          touched={touched}
          errors={errors}
          onBlur={handleBlur}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onSelectClient={() => setShowSelectClientModal(true)}
          onSelectTeacher={() => setShowSelectTeacherModal(true)}
          onClearTeacher={handleClearTeacher}
          onClearClient={handleClearClient}
          onDateSelection={handleDateSelection}
          status={status}
        />
      )}
      {status === STATUS.COMPLETED && (
        <AttendanceList
          report={report}
          attendance={attendance}
          onEdit={handleChangeReport}
        />
      )}

      <SelectorModal
        title="Wybierz nauczyciela"
        isOpen={showSelectTeacherModal}
        onClose={() => setShowSelectTeacherModal(false)}
        values={teacherOptions}
        onSelect={option => handleSelectTeacher(option)}
        selectedIDs={[report.teacherId]}
      />

      <SelectorModal
        title="Wybierz klienta"
        isOpen={showSelectClientModal}
        onClose={() => setShowSelectClientModal(false)}
        values={clientOptions}
        onSelect={option => handleSelectClient(option)}
        selectedIDs={[report.clientId]}
      />
    </div>
  );
}

AttendanceReport.propTypes = {
  lessonRegister: PropTypes.array.isRequired,
  loadLessonRegisters: PropTypes.func.isRequired,
  schoolClasses: PropTypes.array.isRequired,
  loadSchoolClasses: PropTypes.func.isRequired,
  teachers: PropTypes.array.isRequired,
  loadTeachers: PropTypes.func.isRequired,
  students: PropTypes.array.isRequired,
  loadStudents: PropTypes.func.isRequired,
  classStudents: PropTypes.array.isRequired,
  loadClassStudents: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
  loadClients: PropTypes.func.isRequired,
  clientOptions: PropTypes.array.isRequired,
  teacherOptions: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  lessonRegister: state.lessonRegister,
  schoolClasses: state.schoolClasses,
  teachers: state.teachers,
  students: state.students,
  classStudents: state.classStudents,
  clients: state.clients,
  clientOptions: [
    { value: "all", text: "Wszyscy" },
    ...(state.clients || []).map(c => ({
      value: c.id,
      text: `${c.firstName} ${c.lastName}`,
    })),
  ],
  teacherOptions: [
    { value: "all", text: "Wszyscy" },
    ...(state.teachers || []).map(t => ({
      value: t.id,
      text: `${t.firstName} ${t.lastName}`,
    })),
  ],
});

const mapDispatchToProps = {
  loadLessonRegisters: lessonRegisterActions.loadLessonRegisters,
  loadSchoolClasses: schoolClassActions.loadSchoolClasses,
  loadTeachers: teacherActions.loadTeachers,
  loadStudents: studentActions.loadStudents,
  loadClassStudents: classStudentsActions.loadClassStudents,
  loadClients: clientActions.loadClients,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceReport);
