export const validatePerson = (person) => {
  const errors = {};
  const { firstName, lastName, mobile } = person;
  if (!firstName) {
    errors.firstName = "Imię jest wymagane.";
  }
  if (!lastName) {
    errors.lastName = "Nazwisko jest wymagane.";
  }
  if (!mobile) {
    errors.mobile = "Numer telefonu jest wymagany.";
  } else if (mobile.length < 6) {
    errors.mobile = "Numer telefonu jest za krótki.";
  } else if (!mobile.match(/[+]?[()0-9 -]+$/)) {
    errors.mobile = "Nieprawidłowy numer telefonu.";
  }

  return errors;
};

const check = (validate) => {
  if (typeof validate === "function") {
    return validate;
  }
  if (typeof validate === "object") {
    return (label, value) => validate.reduce((result, validation) => {
      if (result) return result;
      return validation(label, value);
    }, null);
  }
  throw new Error(`Validation for type ${typeof validate} is not supported`);
};

export const validateValues = (values, validationConfig) => {
  const errors = {};
  // eslint-disable-next-line array-callback-return
  Object.keys(validationConfig).map((field) => {
    const { label, validate } = validationConfig[field];
    const value = values[field];
    const errMsg = check(validate)(label, value);
    if (errMsg) {
      errors[field] = errMsg;
    }
  });
  return errors;
};

export const mandatory = (label, value) => {
  if (!value) {
    return `Pole ${label} jest wymagane.`;
  }
  return null;
};

export const email = (label, value) => {
  if (!value.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
    return "Nieprawidłowy adres email .";
  }
  return null;
};
