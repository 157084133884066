import React from "react";
import PropTypes from "prop-types";

const SelectInput = ({name, label, defaultOption, value, error, options, readOnly, onChange, className}) => {
  const isValid = e => (e ? "is-invalid" : "");
  return (
    <div className={`form-group ${className}`}>
      <span><strong>{label}</strong></span>
      <div className="field mt-1">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <select
          name={name}
          value={value}
          onChange={onChange}
          className={`form-control ${isValid(error)}`}
          aria-readonly={readOnly}
          disabled={readOnly}
        >
          {defaultOption && <option value="">{defaultOption}</option>}
          {options.map(option => (
            <option key={option.value} value={option.value} >
              {option.text}
            </option>
          ))}
        </select>
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SelectInput.defaultProps = {
  defaultOption: "",
  value: "",
  error: "",
  options: [],
  readOnly: false,
};

export default SelectInput;
