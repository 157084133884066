import React, { useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import PropTypes from "prop-types";
import moment from "moment";
import "../../styles/styles.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/sass/styles.scss";

const localizer = momentLocalizer(moment);

const AttendanceReportCalendar = ({
  onDateSelection,
  startDate,
  endDate,
  validateDateOrder,
  selectionState,
  setSelectionState,
  onNavigateFirstCalendar,
  onNavigateSecondCalendar,
  firstCalendarDate,
  secondCalendarDate,
}) => {
  const [events] = useState([]);

  const checkOutsideRange = (calendarType, date) => {
    const calendarDate = calendarType === "first" ? firstCalendarDate : secondCalendarDate;
    const firstDayOfMonth = moment(calendarDate).startOf("month");
    const lastDayOfMonth = moment(calendarDate).endOf("month");

    return !moment(date).isBetween(firstDayOfMonth, lastDayOfMonth, "day", "[]");
  };

  const handleSelectSlot = (slotInfo, calendarType) => {
    const date = slotInfo.start;
    let valDate;

    if (checkOutsideRange(calendarType, date)) {
      return;
    }

    switch (selectionState) {
      case 0:
        valDate = validateDateOrder(date);
        onDateSelection(valDate.start, valDate.start);
        if (!valDate.repeat) setSelectionState(1);
        break;

      case 1:
        valDate = validateDateOrder(startDate, date);
        onDateSelection(valDate.start, valDate.end);
        if (!valDate.repeat) setSelectionState(2);
        break;

      case 2:
        setSelectionState(1);
        onDateSelection(date, date);
        break;

      default:
        throw new Error(`Unexpected selection state: ${selectionState}`);
    }
  };

  // select fields to highlight between start and end
  const isWithinRange = date => moment(date).isAfter(startDate, "day") && moment(date).isBefore(endDate);

  const isStartDate = date => moment(date).isSame(startDate, "day");

  const isEndDate = date => moment(date).isSame(endDate, "day");

  const dayPropGetter = (date) => {
    if (isStartDate(date)) {
      return {
        className: "selected-day start",
      };
    } else if (isEndDate(date)) {
      return {
        className: "selected-day end",
      };
    } else if (isWithinRange(date)) {
      return {
        className: "selected-day",
      };
    }
    return {};
  };

  const renderCalendarForMonth = (calendarStartDate, handleNavigate, calendarType) => (
    <Calendar
      selectable
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      views={["month"]}
      defaultView={Views.MONTH}
      date={calendarStartDate}
      onNavigate={handleNavigate}
      onSelectSlot={slotInfo => handleSelectSlot(slotInfo, calendarType)}
      dayPropGetter={dayPropGetter}
    />
  );

  return (
    <div className="report-calendar-container my-calendar">
      <div className="row">
        <div className="col-md-6 col-12 mb-4">
          {renderCalendarForMonth(
            firstCalendarDate,
            onNavigateFirstCalendar,
            "first"
          )}
        </div>

        <div className="col-md-6 col-12 mb-4">
          {renderCalendarForMonth(
            secondCalendarDate,
            onNavigateSecondCalendar,
            "second"
          )}
        </div>
      </div>
    </div>
  );
};


AttendanceReportCalendar.propTypes = {
  onDateSelection: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  validateDateOrder: PropTypes.func.isRequired,
  selectionState: PropTypes.number.isRequired,
  setSelectionState: PropTypes.func.isRequired,
  onNavigateFirstCalendar: PropTypes.func.isRequired,
  onNavigateSecondCalendar: PropTypes.func.isRequired,
  firstCalendarDate: PropTypes.instanceOf(Date).isRequired,
  secondCalendarDate: PropTypes.instanceOf(Date).isRequired,
};

AttendanceReportCalendar.defaultProps = {
  startDate: null,
  endDate: null,
};

export default AttendanceReportCalendar;
