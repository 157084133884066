/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React, { useState } from "react";
import PropTypes from "prop-types";

const BooksList = ({ books, onAdd, onEdit }) => {
  const [numToShow, setNumToShow] = useState(25);

  const handleShowMore = () => {
    setNumToShow(numToShow + 25);
  };

  const handleShowAll = () => {
    setNumToShow(books.length);
  };

  return (
    <div>
      <div className="row d-flex mx-0">
        <div className="manage-title">Książki</div>
        <div className="col-block ml-auto">
          <button
            type="button"
            className="btn manage-button btn-success btn-block"
            onClick={onAdd}
          >
            Dodaj nowa książkę
          </button>
        </div>
      </div>

      <div className="">
        <div className="row table-header manage-table-title mx-0 d-flex align-items-center">
          <div className="col-sm-1 col-2 text-center">#</div>
          <div className="col-10">Tytuł</div>
        </div>
        <div className="">
          {books.slice(0, numToShow).map((book, index) => (
            <div
              key={book.id}
              className={`table-row row d-flex manage-text cursor-pointer mx-0 ${
                index % 2 !== 0 ? "table-row-alternate" : ""
              }`}
              onClick={() => onEdit(book)}
            >
              <div className="col-sm-1 col-2 text-center">
                <i className="fas fa-book" />
              </div>
              <div className="col-10">{book.title}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="pt-3 row d-flex justify-content-center mx-0">
        {numToShow < books.length && (
          <div className="col-md-3 form-group">
            <button
              type="button"
              className="btn manage-button manage-red-button btn-block"
              onClick={handleShowMore}
            >
              Pokaż więcej
            </button>
          </div>
        )}

        {numToShow < books.length && (
          <div className="col-md-3 form-group">
            <button
              type="button"
              className="btn manage-button btn-dark btn-block"
              onClick={handleShowAll}
            >
              Pokaż wszystkie
            </button>
          </div>
        )}


      </div>
    </div>
  );
};

BooksList.propTypes = {
  books: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default BooksList;
