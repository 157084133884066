import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ListGroup } from "react-bootstrap";

const ListItemSelector = ({ label, options, onSelect, selectedIDs }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [filter, setFilter] = useState("");

  const filterOptions = () => {
    const valueLower = filter.toLowerCase();
    return options.filter((o) => o.text.toLowerCase().includes(valueLower));
  };

  useEffect(() => {
    setFilteredOptions(filterOptions());
  }, [options]);

  const handleChange = (event) => {
    const { value } = event.target;
    setFilter(value);
    setFilteredOptions(filterOptions());
  };

  return (
    <div className="form-group">
      <span>{label}</span>
      <div className="field">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <input
          type="text"
          name="search"
          className="form-control mb-3"
          value={filter}
          onChange={handleChange}
          placeholder="Szukaj..."
        />

        <div className="">
          <ListGroup
            variant="flush"
            style={{ height: "25vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            {filteredOptions.map((option) => (
              <ListGroup.Item key={option.value} className="border-0">
                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      className={`btn btn-block manage-button manage-teal-button ${
                        selectedIDs.includes(option.value) ? "selected" : ""
                      }`}
                      onClick={() => onSelect(option)}
                    >
                      {option.text}
                    </button>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </div>
    </div>
  );
};

ListItemSelector.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedIDs: PropTypes.arrayOf(PropTypes.string),
};

ListItemSelector.defaultProps = {
  options: [],
  selectedIDs: [],
};

export default ListItemSelector;
