/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import Input from "../common/Input";
import {STATUS} from "../common/Const";
import {calculateDisplayedErrors} from "../common/Helpers";

const TeacherForm = ({firstName, lastName, email, mobile, touched, errors, onBlur, onChange, onSubmit, onCancel, status}) => {
  const err = calculateDisplayedErrors(errors, touched, status);
  const disabled = status === STATUS.SUBMITTING;
  return (
    <div>
      <h2 className="manage-title">Nauczyciel</h2>
      <form id="teacher-form" onSubmit={onSubmit}>
        <div className="form-row">
          <div className="col-md-12 mb-1  ">
            <Input label="Imię" onBlur={onBlur} onChange={onChange} name="firstName" value={firstName} error={err.firstName} placeholder="Imię" />
            <Input label="Nazwisko" onBlur={onBlur} onChange={onChange} name="lastName" value={lastName} error={err.lastName} placeholder="Nazwisko" />
            <Input label="Email" onBlur={onBlur} onChange={onChange} name="email" value={email} error={err.email} placeholder="Email" />
            <Input label="Nr telefonu" onBlur={onBlur} onChange={onChange} name="mobile" value={mobile} error={err.mobile} placeholder="Nr telefonu" />
          </div>
        </div>

        <div className="pt-4 row m-0">
          <div className="col-md-block ml-auto" >
            <button type="submit" className="btn manage-button manage-red-button btn-block " disabled={disabled}>{disabled ? 'Zapisywanie...' : 'Zapisz'}</button>
          </div>
          <div className="col-md-block pl-2 pr-2" >
            <button type="button" className="btn manage-button manage-teal-button btn-block " disabled={disabled} onClick={onCancel}>Powrót</button>
          </div>
        </div>
      </form>

    </div>
  );
};

TeacherForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  mobile: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

TeacherForm.defaultProps = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
};

export default TeacherForm;
