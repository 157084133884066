/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable quotes */
import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import {Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import "../../styles/styles.scss";
import ManageNav from "./ManageNav";
import NotFoundPage from "../NotFoundPage";
import Teacher from "./Teacher";
import Teachers from "./Teachers";
import Client from "./Client";
import Clients from "./Clients";
import Dashboard from "./Dashboard";
import Student from "./Student";
import Students from "./Students";
import SchoolClass from "./SchoolClass";
import SchoolClasses from "./SchoolClasses";
import Login from "./Login";
import {ROLES as ROLE} from "../common/Const";
import * as userActions from "../../redux/actions/usersActions";
import Book from "./Book";
import Books from "./Books";
import HeaderNav from "../HeaderNav";
import AttendanceReport from "../report/AttendanceReport";

const ManagementPage = ({user, signOut, checkToken}) => {
  const menuItems = [
    { name: "Dashboard", route: "/manage/" },
    { name: "Nauczyciele", route: "/manage/teachers" },
    { name: "Klienci", route: "/manage/clients" },
    { name: "Uczniowie", route: "/manage/students" },
    { name: "Zajęcia", route: "/manage/classes" },
    { name: "Książki", route: "/manage/books" },
    { name: "Raporty", route: "/manage/reports" },
  ];

  useEffect(() => {
    if (!user || user.role === ROLE.GUEST) checkToken();
  }, [checkToken]);

  return (
    <div >
      <Helmet>
        <title>Extra English - zarządzanie</title>
      </Helmet>

      { (!user || user.role === ROLE.GUEST) && (
        <>
          <HeaderNav />
          <div className="quiz-page row m-0 p-0 d-flex justify-content-center align-items-center">
            <div className="col-lg-8 col-11" >
              <Login />
            </div>
          </div>
        </>
      )}

      { user && user.role !== ROLE.GUEST && (
        <div>
          <ManageNav items={menuItems} signOut={signOut} />
          <div className="manage-container row m-0 d-flex justify-content-center">
            <div className="col-lg-10 col-11 mt-lg-5 mt-3" id="parent" >
              <Switch>
                <Route path="/manage" render={() => <Dashboard userId={user.id} />} exact />
                <Route path="/manage/teacher/:id" component={Teacher} />
                <Route path="/manage/teachers" component={Teachers} exact />
                <Route path="/manage/client/:id" component={Client} />
                <Route path="/manage/clients" component={Clients} exact />
                <Route path="/manage/student/:id" component={Student} />
                <Route path="/manage/students" component={Students} exact />
                <Route path="/manage/class/:id" component={SchoolClass} />
                <Route path="/manage/classes" component={SchoolClasses} exact />
                <Route path="/manage/book/:id" component={Book} />
                <Route path="/manage/books" component={Books} exact />
                <Route path="/manage/reports" component={AttendanceReport} exact />
                <Route path="/manage/login" component={Login} exact />
                <Route component={NotFoundPage} exact />
              </Switch>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

ManagementPage.propTypes = {
  user: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
  checkToken: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  signOut: userActions.signOut,
  checkToken: userActions.checkToken,
};


export default connect(mapStateToProps, mapDispatchToProps)(ManagementPage);
