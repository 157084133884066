import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as teacherActions from "../../redux/actions/teacherActions";
import * as lessonRegisterActions from "../../redux/actions/lessonRegisterActions";
import * as schoolClassActions from "../../redux/actions/schoolClassActions";
import * as studentActions from "../../redux/actions/studentActions";
import * as classStudentsActions from "../../redux/actions/classStudentsActions";
import {
  calculateDatesBetween,
  formatDate,
  getStudentsInThisClass,
} from "../common/Helpers";
import { STATUS } from "../common/Const";
import DashboardCalendar from "./DashboardCalendar";
import SelectorModal from "../common/SelectorModal";

const Dashboard = ({
  userId,
  schoolClasses,
  loadSchoolClasses,
  teachers,
  loadTeachers,
  students,
  loadStudents,
  classStudents,
  loadClassStudents,
  saveLessonRegister,
  loadLessonRegisters,
  deleteLessonRegister,
  lessonRegister,
}) => {
  const [user, setUser] = useState({});
  const [selectedTeacher, setSelectedTeacher] = useState(user);
  const [scheduledClasses, setScheduledClasses] = useState([]);
  const [status, setStatus] = useState(STATUS.VIEW);
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (schoolClasses.length === 0) {
      loadSchoolClasses().catch((error) => {
        alert(`Loading schoolClasses failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    if (teachers.length === 0) {
      loadTeachers().catch((error) => {
        alert(`Loading teachers failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    const teacher = teachers.find(t => t.email === userId);
    if (teacher) {
      setUser(teacher);
      setSelectedTeacher({ value: teacher.id, text: `${teacher.firstName} ${teacher.lastName}` });
    }
  }, [teachers, userId]);

  useEffect(() => {
    if (lessonRegister.length === 0) {
      loadLessonRegisters().catch((error) => {
        alert(`Loading lessonRegisters failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    if (classStudents.length === 0) {
      loadClassStudents().catch((error) => {
        alert(`Loading classStudents failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    if (students.length === 0) {
      loadStudents().catch((error) => {
        alert(`Loading students failed ${error}`);
      });
    }
  }, []);

  useEffect(() => {
    const updateScheduledClasses = () => {
      const filteredClasses = schoolClasses.filter(
        schoolClass => schoolClass.teacherId === selectedTeacher.value
      );

      const newScheduledClasses = filteredClasses.map((schoolClass) => {
        const scheduledLessons = calculateScheduledClasses(
          schoolClass,
          lessonRegister,
          students,
          classStudents
        );
        return {
          scheduledLessons,
        };
      });

      setScheduledClasses(newScheduledClasses);
    };

    updateScheduledClasses();
  }, [schoolClasses, lessonRegister, classStudents, students, selectedTeacher]);

  const handleTeacherSelect = (option) => {
    setSelectedTeacher(option);
    setIsModalOpen(false);
  };

  const handleRegisterUpdate = async (event) => {
    saveLessonRegister(event.lessonRegister)
      .then((savedRegister) => {
        setStatus(STATUS.VIEW);
      })
      .catch((error) => {
        setErrors({ ...errors, saveError: error.message });
        setStatus(STATUS.SUBMITTED);
      });
  };

  return (
    <div className="mb-5">
      <h2 className="manage-title col-block mb-0">
        Witaj {user.firstName || userId}
      </h2>
      <h4 className="col-block d-flex align-items-center mb-3">
        Oglądasz: &nbsp;
        {selectedTeacher.text || `${userId}`}
        <button
          type="button"
          className="manage-select-button"
          onClick={() => setIsModalOpen(true)}
        >
          Wybierz nauczyciela
        </button>
      </h4>
      <SelectorModal
        title="Wybierz nauczyciela"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        values={teachers.map(t => ({
          value: t.id,
          text: `${t.firstName} ${t.lastName}`,
        }))}
        onSelect={option => handleTeacherSelect(option)}
        selectedIDs={[selectedTeacher.value || user.id]}
      />
      <DashboardCalendar
        scheduledClasses={scheduledClasses}
        teachers={teachers}
        students={students}
        onSubmit={handleRegisterUpdate}
        deleteLessonRegister={deleteLessonRegister}
      />
    </div>
  );
};

Dashboard.propTypes = {
  userId: PropTypes.string.isRequired,
  schoolClasses: PropTypes.array.isRequired,
  loadSchoolClasses: PropTypes.func.isRequired,
  teachers: PropTypes.array.isRequired,
  loadTeachers: PropTypes.func.isRequired,
  students: PropTypes.array.isRequired,
  loadStudents: PropTypes.func.isRequired,
  classStudents: PropTypes.array.isRequired,
  loadClassStudents: PropTypes.func.isRequired,
  saveLessonRegister: PropTypes.func.isRequired,
  deleteLessonRegister: PropTypes.func.isRequired,
  loadLessonRegisters: PropTypes.func.isRequired,
  lessonRegister: PropTypes.array.isRequired,
};

const findLessonRegister = (lessonRegister, lessonId) => lessonRegister.find(lr => lr.lessonId === lessonId) || null;

const createLessonObject = (
  id,
  index,
  date,
  startTime,
  teacherId,
  dayOfWeek,
  classTypeId,
  isRecurring,
  students,
  classStudents
) => ({
  id: `${id}-${index}`,
  defaultLessonDetails: {
    date: date instanceof Date ? formatDate(date) : date,
    dayOfWeek,
    startTime,
    teacherId,
    classTypeId,
    isRecurring,
    comment: "",
  },
  defaultStudents: getStudentsInThisClass(students, classStudents, id),
});

const calculateScheduledClasses = (
  schoolClass,
  lessonRegister,
  students,
  classStudents
) => {
  const {
    id,
    startTime,
    teacherId,
    dayOfWeek,
    isRecurring,
    fromDate,
    tillDate,
    classTypeId,
  } = schoolClass;

  if (!dayOfWeek || !fromDate) {
    return [];
  }
  const dates =
    isRecurring && tillDate
      ? calculateDatesBetween(fromDate, tillDate, dayOfWeek)
      : [fromDate];
  const lessonsArray = dates.map((date, index) => createLessonObject(
    id,
    index,
    date,
    startTime,
    teacherId,
    dayOfWeek,
    classTypeId,
    isRecurring,
    students,
    classStudents
  ));

  return lessonsArray.map(lesson => ({
    ...lesson,
    lessonRegister: findLessonRegister(lessonRegister, lesson.id),
  }));
};

const mapStateToProps = state => ({
  schoolClasses: state.schoolClasses,
  teachers: state.teachers,
  students: state.students,
  classStudents: state.classStudents,
  lessonRegister: state.lessonRegister,
});

const mapDispatchToProps = {
  loadSchoolClasses: schoolClassActions.loadSchoolClasses,
  loadClassStudents: classStudentsActions.loadClassStudents,
  loadTeachers: teacherActions.loadTeachers,
  loadStudents: studentActions.loadStudents,
  saveLessonRegister: lessonRegisterActions.saveLessonRegister,
  loadLessonRegisters: lessonRegisterActions.loadLessonRegisters,
  deleteLessonRegister: lessonRegisterActions.deleteLessonRegister,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
